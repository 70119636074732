import {getCurrencyAmountForSummary} from "../common/adjustment/constants/errorSummaryUtils";
import {convertStringToPercentage} from "../../utils/utils";
import {Button} from "@amzn/awsui-components-react";
import React from "react";
import {downloadAdjustmentFile} from "../../../../common/fileDownloader/getFileFromS3Util";

export const DST_COLUMN_IDENTIFIER = {
    LegalEntity: 'legalEntity',
    MarketplaceId: 'marketplaceId',
    SubBusiness: "subBusiness",
    TotalDSTScopeTransaction: 'totalDSTScopeTransaction',
    TotalTransaction: 'totalTransaction',
    NexusRatio: "nexusRatio",
    DSTRate: "dstRate",
    TotalRevenue: "totalRevenue",
    DSTBaseAmount: "dstBaseAmount",
    DSTLiability : "dstLiability",
    TotalRevenueDstReportingCurrency : "totalRevenueAmountDstReportingCurrency",
    TotalDstBaseAmountDstReportingCurrency: "dstBaseAmountDstReportingCurrency",
    TotalDstLiabilityDstReportingCurrency : "dstLiabilityDstReportingCurrency",
    AdjustmentDescription: "adjustmentDescription",
    UpdatedBy: "updatedBy",
    UpdatedOn: "updatedOn",
    Category : "category",
    DocumentLink: 'link',
};

export const DST_BULK_ADJUSTMENT_FILE_IDENTIFIER = {
    Category: 'Category',
    LegalEntity: 'LegalEntity',
    MarketPlace: 'MarketPlace',
    SubBusiness: 'Sub Business',
    LocalCurrency: 'LocalCurrency',
    DSTReportingCurrency: "DST Reporting Currency",
    TotalTransactions: "Total Transactions",
    TotalDSTScopeTransaction: "Total DST Scope Transaction",
    TotalRevenueLocalCurrency: "Total Revenue(Local Currency)",
    TotalRevenueDSTReportingCurrency : "Total Revenue(DST Reporting Currency)",
    Comment: "Comment",
};

export const API_IDENTIFIER = {
    BusinessCategory: 'businessCategory',
    LegalEntity: 'legalEntity',
    MarketplaceId: 'marketplaceId',
    SubBusiness: 'subBusiness',
    LocalCurrency: 'localCurrency',
    DSTReportingCurrency: "dstReportingCurrency",
};


/**
 * @constant {Array<Object>} dstExcelToAPIFieldsForComparison
 * @description Mapping of Excel column names to API field names for data validation
 */
export const dstExcelToAPIFieldsForComparison = [
    {
        excelKey: DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.Category,
        apiKey: API_IDENTIFIER.BusinessCategory
    },
    {
        excelKey: DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.LegalEntity,
        apiKey: API_IDENTIFIER.LegalEntity
    },
    {
        excelKey: DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.MarketPlace,
        apiKey: API_IDENTIFIER.MarketplaceId
    },
    {
        excelKey: DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.SubBusiness,
        apiKey: API_IDENTIFIER.SubBusiness
    },
    {
        excelKey: DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.LocalCurrency,
        apiKey: API_IDENTIFIER.LocalCurrency
    },
    {
        excelKey: DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.DSTReportingCurrency,
        apiKey: API_IDENTIFIER.DSTReportingCurrency
    }
];

/**
 * @constant {string[]} dstFieldsToCheckForNumericValue
 * @description Array of field names that require numeric value validation.
 */
export const dstFieldsToCheckForNumericValue = [
    DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalTransactions,
    DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalDSTScopeTransaction,
    DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalRevenueLocalCurrency,
    DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalRevenueDSTReportingCurrency,
];

export const dstAuditTableColumnDefinitions = (dstDispatcher) => [
    {
        id: DST_COLUMN_IDENTIFIER.LegalEntity,
        header: "Legal Entity",
        cell: e => e.additionalDataMap?.legalEntity,
        width: 170,
        minWidth: 200,
        isRowHeader: true,
        sortingField: DST_COLUMN_IDENTIFIER.LegalEntity
    },
    {
        id: DST_COLUMN_IDENTIFIER.MarketplaceId,
        header: "Marketplace Id",
        cell: e => e.additionalDataMap?.marketplaceId,
        width: 170,
        minWidth: 165,
        sortingField: DST_COLUMN_IDENTIFIER.MarketplaceId
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalDSTScopeTransaction,
        header: "Total Transaction DST Scope",
        cell: e => e.additionalDataMap?.totalDSTScopeTransaction,
        width: 240,
        minWidth: 240
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalTransaction,
        header: "Total Transaction",
        cell: e => e.additionalDataMap?.totalTransaction,
        width: 200,
        minWidth: 170
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalRevenue,
        header: "Total Revenue",
        cell: e => e.additionalDataMap?.totalRevenue,
        width: 200,
        minWidth: 170
    },
    {
        id: DST_COLUMN_IDENTIFIER.AdjustmentDescription,
        header: "Adjustment Description",
        cell: e => e.adjustment?.description,
        width: 250,
        minWidth: 250
    },
    {
        id: DST_COLUMN_IDENTIFIER.UpdatedBy,
        header: "Updated By",
        cell: e => e.updatedBy,
        width: 200,
        minWidth: 170
    },
    {
        id: DST_COLUMN_IDENTIFIER.UpdatedOn,
        header: "Updated On",
        cell: e => e.timestamp,
        width: 200,
        minWidth: 170
    },
    {
        id: DST_COLUMN_IDENTIFIER.DocumentLink,
        header: "Document Link",
        cell: item => {
            const docHubDocumentId = item.additionalDataMap?.docHubDocumentId;
            if (docHubDocumentId) {
                return (
                    <Button iconName="download"
                            wrapText={false}
                            onClick={() => downloadAdjustmentFile(dstDispatcher, {
                                docHubDocumentId: docHubDocumentId
                            })}>
                        View Document
                    </Button>
                )
            }
            return (<></>);
        },
        width: 200,
        minWidth: 170
    },
];

export const dstColumnDefinitions = [
    {
        id: DST_COLUMN_IDENTIFIER.Category,
        cell: item => {
            return item.businessCategory;
        },
        header: 'Category',
    },
    {
        id: DST_COLUMN_IDENTIFIER.LegalEntity,
        cell: item => {
            return item.legalEntity;
        },
        header: 'Legal Entity',
    },
    {
        id: DST_COLUMN_IDENTIFIER.MarketplaceId,
        cell: item => {
            return item.marketplaceId;
        },
        header: 'Marketplace Id',
    },
    {
        id: DST_COLUMN_IDENTIFIER.SubBusiness,
        cell: item => {
            return item.subBusiness;
        },
        header: 'Sub Business',
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalDSTScopeTransaction,
        cell: item => {
            return getCurrencyAmountForSummary(item.totalDSTScopeTransaction);
        },
        header: 'Total Transaction DST Scope',
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalTransaction,
        cell: item => {
            return getCurrencyAmountForSummary(item.totalTransaction);
        },
        header: 'Total Transaction',
    },
    {
        id: DST_COLUMN_IDENTIFIER.NexusRatio,
        cell: item => {
            return convertStringToPercentage(item.nexusRatio);
        },
        header: 'Nexus Ratio',
    },
    {
        id: DST_COLUMN_IDENTIFIER.DSTRate,
        cell: item => {
            return convertStringToPercentage(item.dstRate);
        },
        header: 'DST Rate',
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalRevenue,
        cell: item => {
            return getCurrencyAmountForSummary(item.totalRevenue, item.localCurrency);
        },
        header: 'Total Revenue (Local Currency)',
    },
    {
        id: DST_COLUMN_IDENTIFIER.DSTBaseAmount,
        cell: item => {
            return getCurrencyAmountForSummary(item.dstBaseAmount, item.localCurrency);
        },
        header: 'DST Base Amount (Local Currency)',
    },
    {
        id: DST_COLUMN_IDENTIFIER.DSTLiability,
        cell: item => {
            return getCurrencyAmountForSummary(item.dstLiability, item.localCurrency);
        },
        header: 'DST Liability (Local Currency)',
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalRevenueDstReportingCurrency,
        cell: item => {
            return getCurrencyAmountForSummary(item.totalRevenueAmountDstReportingCurrency, item.dstReportingCurrency);
        },
        header: 'Total Revenue (DST Reporting Currency)',
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalDstBaseAmountDstReportingCurrency,
        cell: item => {
            return getCurrencyAmountForSummary(item.dstBaseAmountDstReportingCurrency, item.dstReportingCurrency);
        },
        header: 'DST Base Amount (DST Reporting Currency)',
    },
    {
        id: DST_COLUMN_IDENTIFIER.TotalDstLiabilityDstReportingCurrency,
        cell: item => {
            return getCurrencyAmountForSummary(item.dstLiabilityDstReportingCurrency, item.dstReportingCurrency);
        },
        header: 'DST Liability (DST Reporting Currency)',
    },
];

export const INVALID_INPUT = "Invalid Input";
import {getAPIInputContext} from "../utils/apiUtils";
import {API} from "aws-amplify";
import {GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL} from "../../ApiPaths";
import {downloadFileFromPresignedURL} from "../utils/s3Utils";
import {GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL_OPERATION_NAME} from "../constants/constants";

export function downloadAdjustmentFile(dispatcher, request) {
    return getAPIInputContext().then(input => {
        input.body.docHubDocumentId = request.docHubDocumentId;
        input.body.operationName = GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL_OPERATION_NAME.DOWNLOAD;
        return API.post("TWIN_API", GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL, input).then(resp => {
            if (resp.status === 200) {
                downloadFileFromPresignedURL(resp.body.presignedUrl);
            } else {
                dispatcher({
                    type: "API_REQUEST_FAILURE", payload: {
                        errMsg: 'Failed to download adjustment file.',
                        dispatcher: dispatcher,
                    }
                });
            }
        })
    });
}
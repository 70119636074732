import * as XLSX from "xlsx";
import {BULK_ADJUSTMENT_USE_CASE} from "../../../common/constants/constants";
import {
    DST_BULK_ADJUSTMENT_FILE_IDENTIFIER,
    DST_COLUMN_IDENTIFIER,
    dstExcelToAPIFieldsForComparison, dstFieldsToCheckForNumericValue
} from "../components/DigitalServicesTax/Constants";
import {isValidFloatNumber} from "./utils";
import {getAllReportingAggregatedDataForDST} from "../components/redux/exceptionAPIs";

/**
 * Reads an Excel file and converts it into JSON data.
 * @param {File} file - The Excel file to read.
 * @returns {Promise<Object[]>} Resolves to an array of objects representing the Excel rows.
 * @throws Will throw an error if the file cannot be read or parsed.
 */
export async function readExcelFile(file) {
    try {
        const data = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = e => resolve(e.target.result);
            reader.onerror = reject;
            reader.readAsBinaryString(file);
        });

        const workbook = XLSX.read(data, {type: 'binary'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        return XLSX.utils.sheet_to_json(worksheet);
    } catch (error) {
        throw error;
    }
}

/**
 * Processes bulk adjustments based on the given Excel file and use case.
 * @param {File} file - The Excel file to process.
 * @param {string} bulkAdjustmentUseCase - The use case for the bulk adjustment.
 * @param {string} parentWorkflowId - The workflow ID for aggregation data.
 * @param {string} useCase - Phastos use case.
 * @returns {Promise<Object>} Returns the processing results, including validity, a validation report, and adjustment info list.
 */
export async function processBulkAdjustment(file, bulkAdjustmentUseCase, parentWorkflowId, useCase) {
    const defaultResponse = {isValid: false, validationReport: {}, adjustmentInfoList: []};

    // Only onboarded for DST use case right now.
    if (!file || BULK_ADJUSTMENT_USE_CASE.DST !== bulkAdjustmentUseCase) {
        return defaultResponse;
    }

    try {
        const aggregatedData = await fetchAllAggregatedData(parentWorkflowId, useCase, file);
        const validationResult = validateExcelDataAgainstApiData(aggregatedData.excelData, aggregatedData.apiData);

        if (!validationResult.isValid) {
            return {...defaultResponse, validationReport: validationResult};
        }

        const adjustmentInfoList = aggregatedData.excelData.reduce((adjustmentInfoList, excelRow) => {
            const match = aggregatedData.apiData.find(apiRow => {
                const apiRowJson = JSON.parse(apiRow);
                return dstExcelToAPIFieldsForComparison.every(field =>
                    String(excelRow[field.excelKey] || '').trim().toLowerCase() ===
                    String(apiRowJson[field.apiKey] || '').trim().toLowerCase()
                );
            });

            if (match) {
                const apiRowJson = JSON.parse(match);
                adjustmentInfoList.push({
                    boxLabel: apiRowJson['hashKey'],
                    value: '0.0',
                    operation: "UPDATE",
                    description: excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.Comment],
                    additionalDataMap: {
                        [DST_COLUMN_IDENTIFIER.LegalEntity]: String(excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.LegalEntity]),
                        [DST_COLUMN_IDENTIFIER.MarketplaceId]: String(excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.MarketPlace]),
                        [DST_COLUMN_IDENTIFIER.TotalTransaction]: String(excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalTransactions]),
                        [DST_COLUMN_IDENTIFIER.TotalDSTScopeTransaction]: String(excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalDSTScopeTransaction]),
                        [DST_COLUMN_IDENTIFIER.TotalRevenue]: String(excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalRevenueLocalCurrency]),
                        [DST_COLUMN_IDENTIFIER.TotalRevenueDstReportingCurrency]: String(excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalRevenueDSTReportingCurrency]),
                    }
                });
            }
            return adjustmentInfoList;
        }, []);

        return {isValid: true, validationReport: {}, adjustmentInfoList};
    } catch (error) {
        console.error(`Error while processing and validating file for parentWorkflowId: ${parentWorkflowId}, useCase: ${useCase} :`, error);
        return defaultResponse;
    }
}

/**
 * Fetches all aggregated data from the API and reads the Excel file.
 * @param {string} parentWorkflowId - The workflow ID for data aggregation.
 * @param {string} useCase - Phastos use case.
 * @param {File} file - The Excel file for processing.
 * @returns {Promise<{apiData: Object[], excelData: Object[]}>} Aggregated data from the API and parsed Excel data.
 * @throws Will throw an error if the API request or Excel file processing fails.
 */
async function fetchAllAggregatedData(parentWorkflowId, useCase, file) {
    try {
        const request = {
            parentWorkflowId: parentWorkflowId,
            useCase: useCase
        };
        const apiData = await getAllReportingAggregatedDataForDST(request);
        const excelData = await readExcelFile(file);
        return {apiData: apiData, excelData};
    } catch (error) {
        console.error(`Error while fetching all aggregated data for parentWorkflowId: ${parentWorkflowId}, useCase: ${useCase} :`, error);
        return {apiData: null, excelData: null};
    }
}

/**
 * Creates a map of API data for efficient lookups based on specified fields.
 * @param {Array} apiData - Array of stringified JSON objects containing API data.
 * @returns {Map} A map where keys are concatenated field values and values are parsed API data objects.
 * @throws {Error} If apiData is not an array.
 */
function createApiDataMap(apiData) {
    if (!Array.isArray(apiData)) {
        throw new Error('apiData must be an array');
    }

    const apiDataMap = new Map();
    apiData.forEach(row => {
        const apiRowJson = JSON.parse(row);
        const key = generateComparisonKey(apiRowJson, dstExcelToAPIFieldsForComparison, 'api');
        apiDataMap.set(key, apiRowJson);
    });
    return apiDataMap;
}

/**
 * Generates a comparison key by concatenating specified field values from a data row
 *
 * @param {Object} row - The data row object containing the values to be compared
 * @param {Array<Object>} fields - Array of field mapping objects containing excelKey and apiKey
 * @param {string} [keyType='excel'] - Type of key to use for comparison ('excel' or 'api')
 * @returns {string} A pipe-separated string of lowercase trimmed values from the specified fields
 */
function generateComparisonKey(row, fields, keyType = 'excel') {
    return fields
        .map(field => {
            const key = keyType === 'excel' ? field.excelKey : field.apiKey;
            return String(row[key] || '').trim().toLowerCase();
        })
        .join('|');
}

/**
 * Validates a single Excel row against API data and checks for specific validation rules.
 * @param {Object} excelRow - The Excel row object to validate.
 * @param {number} index - Zero-based index of the row in the Excel file.
 * @param {Map} apiDataMap - Map of API data to validate against.
 * @returns {Object} Validation result object.
 * @property {boolean} hasError - Indicates if any validation errors were found.
 * @property {Object} unmatchedRecord - Details of validation failures including:
 *   @property {number} rowNumber - Excel row number (index + 2).
 *   @property {Array<string>} errors - Array of error messages.
 *   @property {...*} - All original fields from excelRow.
 */
function validateExcelRow(excelRow, index, apiDataMap) {
    let hasError = false;
    const unmatchedRecord = {
        rowNumber: index + 2,
        errors: []
    };

    // Copy all fields from excelRow to unmatchedRecord
    Object.assign(unmatchedRecord, excelRow);

    // Check for API data match
    const excelKey = generateComparisonKey(excelRow, dstExcelToAPIFieldsForComparison, 'excel');
    const matchFound = apiDataMap.has(excelKey);

    if (!matchFound) {
        hasError = true;
        unmatchedRecord.errors.push('No match found in database');
    }

    // Validate numeric fields
    dstFieldsToCheckForNumericValue.forEach((field) => {
        if (isNaN(excelRow[field]) || !isValidFloatNumber(excelRow[field])) {
            hasError = true;
            unmatchedRecord.errors.push(`Invalid numeric value in ${field}`);
        }
    });

    // Validate comment
    if (!excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.Comment] || excelRow[DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.Comment].trim() === "") {
        hasError = true;
        unmatchedRecord.errors.push('Comment is empty');
    }

    return {
        hasError,
        unmatchedRecord
    };
}

/**
 * Compares datasets from Excel and API, performing validation and generating a report.
 * @param {Object[]} excelData - Array of Excel data rows.
 * @param {Object[]} apiData - Array of API data rows.
 * @returns {Object} Validation report with matched and unmatched records and a summary.
 * @throws Will throw an error if inputs are invalid or processing fails.
 */
function compareDatasetsAndPerformValidations(excelData, apiData) {
    const validationReport = {
        summary: {
            total: excelData.length,
            matched: 0,
            unmatched: 0
        },
        details: {
            unmatched: []
        }
    };

    if (!Array.isArray(excelData)) {
        throw new Error('excelData must be an array');
    }

    const apiDataMap = createApiDataMap(apiData);

    excelData.forEach((excelRow, index) => {
        const { hasError, unmatchedRecord } = validateExcelRow(excelRow, index, apiDataMap);

        // If any validation failed, add to unmatched records
        if (hasError) {
            validationReport.details.unmatched.push(unmatchedRecord);
            validationReport.summary.unmatched++;
        } else {
            validationReport.summary.matched++;
        }
    });

    return validationReport;
}

/**
 * Validates Excel data against API data and generates a validation report.
 * @param {Object[]} excelData - Array of Excel data rows.
 * @param {Object[]} apiData - Array of API data rows.
 * @returns {Object} Validation result, including validity and a detailed report.
 * @throws Will throw an error if the validation process fails.
 */
function validateExcelDataAgainstApiData(excelData, apiData) {
    try {
        // Generate detailed report after comparing datasets and performing validations
        const report = compareDatasetsAndPerformValidations(excelData, apiData);

        // Return detailed results for further processing if needed
        return {
            isValid: report.details.unmatched.length === 0,
            report
        };

    } catch (error) {
        console.error('Error during comparison:', error);
        throw error;
    }
}

import React, {useEffect, useState} from "react";
import {downloadJsonAsExcel} from "../../../utils/utils";
import {Button, ColumnLayout, ContentLayout, SpaceBetween} from "@amzn/awsui-components-react";
import {Container, Header} from "@amzn/awsui-components-react/polaris";
import Alert from "@amzn/awsui-components-react/polaris/alert";

export default function ValidationReport(props) {
    const {
        validationReport
    } = props;

    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (validationReport) {
            setIsValid(validationReport.isValid);
        }
    }, [validationReport]);

    if (!validationReport || !validationReport.report) return (<></>);

    const {report} = validationReport;
    const {summary, details} = report;

    const createValidationReportJson = () => {
        return details.unmatched.map(record => {
            // Remove rowNumber and errors from the data
            const {rowNumber, errors, ...recordData} = record;
            return {
                'Row Number': rowNumber,
                'Errors': errors ? errors.join('; ') : '',
                ...recordData
            };
        });
    };

    const handleDownloadClick = () => {
        downloadJsonAsExcel(createValidationReportJson(), 'validation_report')
    }

    return (
        <ContentLayout
            defaultPadding
            header={
                <Alert
                    type={isValid ? 'success' : 'error'}
                >
                    {isValid ? 'Validation Successful' : 'Validation Failed'}
                </Alert>
            }
        >
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                        Validation Summary
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="s">
                    <ColumnLayout columns={3}>
                        <div>
                            Total Records: {summary.total}
                        </div>
                        <div>
                            Matched: {summary.matched}
                        </div>
                        <div>
                            Unmatched: {summary.unmatched}
                        </div>
                    </ColumnLayout>
                    <Button iconName="download"
                            disabled={!details.unmatched || details.unmatched.length === 0}
                            onClick={handleDownloadClick}>
                        Download Detailed Validation Report
                    </Button>
                </SpaceBetween>

            </Container>
        </ContentLayout>
    );
};
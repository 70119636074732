import React, {useEffect, useState} from "react";
import {Button, Header, Pagination, PropertyFilter, SpaceBetween} from "@amzn/awsui-components-react";
import {Table} from "@amzn/awsui-components-react/polaris";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {EmptyState, getMatchesCountText, PROPERTY_FILTERING_I18N_CONSTANTS} from "../../../phastos/table-config";
import {getReportingAggregatedDataForDST} from "../redux/exceptionAPIs";
import {isNull, isUndefined, max} from "lodash";
import {convertStringToFloatWithPrecision, convertStringToPercentage, downloadJsonAsExcel} from "../../utils/utils";
import DSTAdjustmentModal from "./DSTAdjustmentModal";
import {dstColumnDefinitions} from "./Constants";
import BulkAdjustmentModal from "../common/adjustment/BulkAdjustmentModal";
import {BULK_ADJUSTMENT_USE_CASE} from "../../../../common/constants/constants";

function EntityBreakdown(props) {

    const {
        parentWorkflowId,
        useCase,
        lastUpdatedOn,
        setLastUpdatedOn,
        entityFilter,
        dstDispatcher
    } = props;

    const INITIAL_PAGE_NUMBER = 1;
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [totalPageNumber, setTotalPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [currentPageNumber, setCurrentPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [openEnd, setOpenEnd] = useState(true);
    const [selectedItem, setSelectedItem] = useState();
    const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);
    const [showBulkAdjustmentModal, setShowBulkAdjustmentModal] = useState(false);
    const FIXED_PRECISION = 2;
    const DST_RATE_FIXED_PRECISION = 3;
    const onPaginationChange = (event) => {
        const pageNumber = event.detail.currentPageIndex;
        setCurrentPageNumber(pageNumber);
        setTotalPageNumber(max([totalPageNumber, pageNumber]));

        if (isNull(data[pageNumber]) || isUndefined(data[pageNumber])) {
            refreshData(pageNumber);
        } else {
            setCurrentPageData(data[pageNumber]);
            setLoading(false);
        }
    }

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disableAdjustment, setDisableAdjustment] = useState(true);
    const [disableBulkAdjustment, setDisableBulkAdjustment] = useState(false);

    const adjustmentModalDismissHandler = () => {
        setShowAdjustmentModal(false);
    };

    const bulkAdjustmentModalDismissHandler = () => {
        setShowBulkAdjustmentModal(false);
    };

    const refreshData = (pageNumber) => {
        setLoading(true);
        const request = {
            data: data,
            setData: setData,
            setLastUpdatedOn: setLastUpdatedOn,
            lastEvaluatedKey: lastEvaluatedKey,
            setLastEvaluatedKey: setLastEvaluatedKey,
            parentWorkflowId: parentWorkflowId,
            useCase: useCase,
            currentPageNumber: pageNumber ? pageNumber : currentPageNumber,
            setOpenEnd: setOpenEnd,
            setLoading: setLoading
        };
        getReportingAggregatedDataForDST(request);
    }

    useEffect(() => {
        refreshData(INITIAL_PAGE_NUMBER);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isNull(data[currentPageNumber]) && !isUndefined(data[currentPageNumber])) {
            setCurrentPageData(data[currentPageNumber]);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function getFlatEntityMarketPlaceData() {
        const dataList = Object.values(data).flatMap(page => Object.values(page));
        const updatedDataList = [];
        for(let i = 0;i<dataList.length;i++)
        {
            let val = dataList[i];
            let cur = {
                "Category" : val?.businessCategory,
                "Legal Entity" : val?.legalEntity,
                "Legal Entity Short Name" : val?.legalEntityShortName,
                "Marketplace Id" : val?.marketplaceId,
                "Sub Business" : val?.subBusiness,
                "Total Transaction" : val?.totalTransaction,
                "Total DST Scope Transaction" : val?.totalDSTScopeTransaction,
                "Nexus Ratio" : convertStringToPercentage(val?.nexusRatio),
                "DST Rate" : convertStringToFloatWithPrecision(val?.dstRate, DST_RATE_FIXED_PRECISION),
                "Total Revenue (Local Currency)" : val?.totalRevenue,
                "Total Revenue (DST Reporting Currency)" : convertStringToFloatWithPrecision(val?.totalRevenueAmountDstReportingCurrency, FIXED_PRECISION),
                "Total DST Base Amount (DST Reporting Currency)" : convertStringToFloatWithPrecision(val?.dstBaseAmountDstReportingCurrency, FIXED_PRECISION),
                "Total DST Liability (DST Reporting Currency)"  : convertStringToFloatWithPrecision(val?.dstLiabilityDstReportingCurrency, FIXED_PRECISION),
                "Local Currency" : val?.localCurrency,
                "DST Reporting Currency" : val?.dstReportingCurrency,
            }
            updatedDataList.push(cur);
        }
        return updatedDataList;
    }


    const {items, filteredItemsCount, collectionProps, propertyFilterProps,
        actions: filterAction} = useCollection(
        currentPageData,
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No summary"
                        subtitle="No summary to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                    />
                ),
            },
            propertyFiltering: {
                filteringProperties: [
                    {
                        propertyLabel: 'Legal Entity',
                        key: 'legalEntity',
                        groupValuesLabel: 'Legal Entity',
                        operators: ['=', '!='],
                    },
                    {
                        propertyLabel: 'Category',
                        key: 'category',
                        groupValuesLabel: 'Category',
                        operators: ['=', '!='],
                    },
                    {
                        propertyLabel: 'Sub Business',
                        key: 'subBusiness',
                        groupValuesLabel: 'subBusiness',
                        operators: ['=', '!='],
                    },
                    {
                        propertyLabel: 'Market Place',
                        key: 'marketplaceId',
                        groupValuesLabel: 'marketplaceId',
                        operators: ['=', '!='],
                    }
                ],
                empty: <EmptyState title="No matches"/>,
                noMatch: (
                    <EmptyState
                        title="No matches"
                    />
                ),
            },
            selection: {}
        }
    );

    const {setPropertyFiltering} = filterAction;

    useEffect(() => {
        if (!isNull(entityFilter)) {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [{
                    propertyKey: 'legalEntity',
                    operator: '=',
                    value: entityFilter,
                }],
            })
        } else {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityFilter]);


    const onAdjustmentCloseHandler = async () => {
        refreshData();
        setDisableAdjustment(true);
    }

    const onBulkAdjustmentCloseHandler = async () => {
        refreshData();
        setDisableBulkAdjustment(false);
    }

    return (
        <SpaceBetween size="xl">
            <Table
                {...collectionProps}
                loading={loading}
                selectionType="single"
                selectedItems={selectedItem}
                onSelectionChange={({detail}) => {
                    setSelectedItem(detail.selectedItems)
                    setDisableAdjustment(false);
                    }
                }
                header={
                    <div>
                        <Header
                            actions={<SpaceBetween size='xs' direction='horizontal'>
                                <Button onClick={refreshData}>Refresh</Button>
                                <Button onClick={() => {
                                    downloadJsonAsExcel(getFlatEntityMarketPlaceData(), "EntityBreakdown")
                                }}>Download</Button>
                                <Button disabled={disableAdjustment} onClick={() => {
                                    setShowAdjustmentModal(true);
                                }}>Apply adjustments</Button>
                                <Button disabled={disableBulkAdjustment} onClick={() => {
                                    setShowBulkAdjustmentModal(true);
                                }}>Apply Bulk adjustments</Button>
                            </SpaceBetween>}
                            description={`Last updated: ${lastUpdatedOn}`}
                        >
                            Entity Breakdown
                        </Header>
                    </div>
                }
                columnDefinitions={dstColumnDefinitions}
                resizableColumns={false}
                items={items}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
                pagination={<Pagination
                    currentPageIndex={currentPageNumber}
                    onChange={onPaginationChange}
                    pagesCount={totalPageNumber}
                    openEnd={openEnd}
                />}
            />
            {showAdjustmentModal ? <DSTAdjustmentModal
                showAdjustmentModal={showAdjustmentModal}
                adjustmentModalDismissHandler={adjustmentModalDismissHandler}
                dstItem={selectedItem[0]}
                useCase={useCase}
                parentWorkflowId={parentWorkflowId}
                onAdjustmentCloseHandler={onAdjustmentCloseHandler}
                dstDispatcher={dstDispatcher}
            /> : <></>}
            {showBulkAdjustmentModal ? <BulkAdjustmentModal
                bulkAdjustmentUseCase={BULK_ADJUSTMENT_USE_CASE.DST}
                showBulkAdjustmentModal={showBulkAdjustmentModal}
                useCase={useCase}
                parentWorkflowId={parentWorkflowId}
                bulkAdjustmentModalDismissHandler={bulkAdjustmentModalDismissHandler}
                onBulkAdjustmentCloseHandler={onBulkAdjustmentCloseHandler}
                dispatcher={dstDispatcher}
            /> : <></>}
        </SpaceBetween>
    )
}

export default EntityBreakdown;
import {getOnCallContactLink} from "../utils/commonUtils";

export const UPLOAD_PREFIX = "UI_Input_Files";
export const TEMPLATE_FOLDER = "Templates";
export const FORMATS = ".csv,.xlsx,.txt,.xls,.xlsm"
export const ARTIFACTS_FORMAT = ".pdf,.png,.jpg,.jpeg,.docx" + FORMATS
export const DESC = "desc";
export const ASC = "asc";
export const CLIENT_NAME = "TwinService";
export const BRAAVOS_REPORT_SUCCESS_STATUS = "Success";

export const HMRC_VAT_RETURN_SHEET_NAME = "HMRC VAT Return";
export const HMRC_VAT_RETURN_FILE_NAME = "Workbook.xlsx";
export const WEBSITE_CLIENT_NAME = "TwinWebsite";
export const PHASTOS = "PHASTOS";
export const EXCALIBUR = 'Excalibur';
export const UNDERSCORE = '_';
export const FILING = 'FILING';
export const BACKSLASH =  '/';
export const PFX_EXTENSION = '.pfx';
export const SUBMISSION_TYPE_UStVA_2023 = 'UStVA_2023';
export const ADMINISTRATOR_ROLE = "Administrator";
export const APPROVER_ROLE = "Approver";
export const REVIEWER_ROLE = "Reviewer";
export const TOM_URL_PREFIX = "https://tom-na-iad.iad.proxy.amazon.com/tom-lite/invoice-search/invoice-details/";

export const DOCUMENT_TYPE = {
    ATTACHMENTS: "ATTACHMENTS",
    ADJUSTMENTS: "ADJUSTMENTS"
};

export const BULK_ADJUSTMENT_USE_CASE = {
    DST: "DST"
}

export const GET_PRESIGNED_URL_OPERATION_NAME = {
    UPLOAD: "UPLOAD",
    DOWNLOAD: "DOWNLOAD"
}

export const GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL_OPERATION_NAME = {
    UPLOAD: "UPLOAD",
    DOWNLOAD: "DOWNLOAD"
}

export const NOT_AUTHORIZED_FLASH_BAR_PROPS = {
    header: "User is not authorized to access this page",
    type: "error",
    content: (<div>{"In-case this is an error, kindly contact "}
        {getOnCallContactLink()}
    </div>),
};

export const INTERNAL_ERROR_FLASH_BAR_PROPS = {
    header: "Some internal error occurred. Please try again later",
    type: "error",
    content: (<div>{"If this issue persists, kindly contact "}
        {getOnCallContactLink()}
    </div>),
};

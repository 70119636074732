import React, {useState} from "react";
import {Button, FormField, Modal, SpaceBetween} from "@amzn/awsui-components-react";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload"
import {getBulkAdjustmentTemplateData} from "../../../../../common/utils/commonUtils";
import {downloadJsonAsExcel} from "../../../utils/utils";
import {processBulkAdjustment, readExcelFile} from "../../../utils/validationUtils";
import {uploadAdjustmentFile} from "../../../../../common/fileUploader/putFileInS3Util";
import {getAPIInputContext} from "../../../../../common/utils/apiUtils";
import {DOCUMENT_TYPE} from "../../../../../common/constants/constants";
import {boxLevelAdjustment} from "../../../../phastos/components/phastosAPIs";
import ValidationReport from "./ValidationReport";
import {BULK_ADJUSTMENT_FORMATS, BULK_ADJUSTMENT_MAX_ALLOWED_ROWS, BULK_ADJUSTMENT_MAX_FILE_SIZE} from "../constants";

/**
 * @module BulkAdjustmentModal
 * @description A React component that handles bulk adjustment operations through file upload
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.bulkAdjustmentUseCase - The use case identifier for bulk adjustment
 * @param {string} props.parentWorkflowId - ID of the parent workflow
 * @param {string} props.useCase - The current use case
 * @param {boolean} props.showBulkAdjustmentModal - Controls modal visibility
 * @param {Function} props.bulkAdjustmentModalDismissHandler - Handler for modal dismissal
 * @param {Function} props.onBulkAdjustmentCloseHandler - Handler for modal close action
 * @param {Function} props.dispatcher - Dispatch function for state updates
 *
 * @returns {JSX.Element} Renders a modal component for bulk adjustment operations
 */
export default function BulkAdjustmentModal(props) {
    const {
        bulkAdjustmentUseCase,
        parentWorkflowId,
        useCase,
        showBulkAdjustmentModal,
        bulkAdjustmentModalDismissHandler,
        onBulkAdjustmentCloseHandler,
        dispatcher,
    } = props;

    /**
     * Component State:
     * @property {string} invalidFileErrorMessage - Stores error message for invalid file uploads
     * @property {boolean} disableSubmitButton - Controls submit button state
     * @property {boolean} isFileProcessing - Indicates if file is being processed
     * @property {Object} validationReport - Stores validation results
     * @property {Array} selectedFile - Stores the selected file(s) for upload
     */
    const [invalidFileErrorMessage, setInvalidFileErrorMessage] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const [isFileProcessing, setIsFileProcessing] = useState(false);
    const [validationReport, setValidationReport] = useState();
    const [selectedFile, setSelectedFile] = useState([]);
    const templateData = getBulkAdjustmentTemplateData(bulkAdjustmentUseCase);
    const templateJson = templateData.jsonTemplateData;
    const templateFileName = templateData.templateFileName;

    const validateFile = async (e) => {
        try {
            const file = e.detail.value[0];
            setInvalidFileErrorMessage("");
            setValidationReport(null);
            setSelectedFile(e.detail.value);
            if (!file) {
                setDisableSubmitButton(true);
                return;
            }
            if (file.size > BULK_ADJUSTMENT_MAX_FILE_SIZE) {
                setInvalidFileErrorMessage("File size should be less than 2MB");
                return;
            }
            const excelData = await readExcelFile(file);
            if (excelData.length > BULK_ADJUSTMENT_MAX_ALLOWED_ROWS) {
                setInvalidFileErrorMessage(`File should contain less than ${BULK_ADJUSTMENT_MAX_ALLOWED_ROWS} rows`);
                return;
            }
            setDisableSubmitButton(false);
        } catch (error) {
            setInvalidFileErrorMessage("Unable to read the file");
            setDisableSubmitButton(true);
        }
    };

    async function submit() {
        try {
            setIsFileProcessing(true);
            const {isValid, validationReport, adjustmentInfoList} = await processBulkAdjustment(
                selectedFile[0],
                bulkAdjustmentUseCase,
                parentWorkflowId,
                useCase
            );
            if (!isValid) {
                setInvalidFileErrorMessage("Invalid File");
                setDisableSubmitButton(true);
                setIsFileProcessing(false);
                setValidationReport(validationReport);
                return;
            }
            await onSubmitHandler(adjustmentInfoList);
        } catch (error) {
            console.error("Error during submission:", error);
            setInvalidFileErrorMessage("An error occurred during processing.");
            setIsFileProcessing(false);
            setDisableSubmitButton(true);
        }
    }

    const onSubmitHandler = async (adjustmentInfoList) => {
        await uploadAdjustmentFile(dispatcher, {
            parentWorkflowId: parentWorkflowId,
            fileName: selectedFile[0].name,
            documentType: DOCUMENT_TYPE.ADJUSTMENTS,
            useCase: useCase,
            file: selectedFile[0]
        }).then(docHubDocumentId => {
            getAPIInputContext().then(input => {
                adjustmentInfoList.forEach((adjustmentInfo) => {
                    adjustmentInfo.additionalDataMap.docHubDocumentId = docHubDocumentId;
                });
                input.body.parentWorkflowId = parentWorkflowId;
                input.body.boxLevelAdjustmentsInfoList = adjustmentInfoList;
                input.body.useCase = useCase;
                boxLevelAdjustment(input, dispatcher);
            })
        });
        bulkAdjustmentModalDismissHandler();
        onBulkAdjustmentCloseHandler();
    }

    return (
        <Modal
            visible={showBulkAdjustmentModal}
            onDismiss={bulkAdjustmentModalDismissHandler}
            header={'Bulk Adjustments'}
            closeAriaLabel="Close dialog"
            size="large"
            footer={
                <div id="template-footer">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button iconName="download" onClick={() => {
                            downloadJsonAsExcel(templateJson, templateFileName)
                        }}>Download Template</Button>
                    </SpaceBetween>
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={bulkAdjustmentModalDismissHandler}>Cancel</Button>
                        <Button loading={isFileProcessing} disabled={disableSubmitButton} variant="primary"
                                onClick={submit}>Submit</Button>
                    </SpaceBetween>
                </div>
            }
        >
            <SpaceBetween size="s">
                <FormField
                    label="Choose Bulk Adjustment File"
                    errorText={invalidFileErrorMessage}
                >
                    <FileUpload
                        onChange={validateFile}
                        value={selectedFile}
                        accept={BULK_ADJUSTMENT_FORMATS}
                        i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e ? "Drop files to upload" : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error",
                            warningIconAriaLabel: "Warning"
                        }}
                        showFileLastModified
                        showFileSize
                        showFileThumbnail
                        tokenLimit={3}
                        constraintText="File should not exceed 200 rows or 2MB in size."
                    />
                </FormField>
                <ValidationReport validationReport={validationReport}/>
            </SpaceBetween>
        </Modal>
    );
}
import {getAPIInputContext} from "../utils/apiUtils";
import {API} from "aws-amplify";
import {GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL, PHASTOS_GET_S3_PRESIGNED_URL} from "../../ApiPaths";
import axios from "axios";
import {registerAdjustmentDocument, registerUploadedDocument} from "./registerUploadedDocument";
import {
    GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL_OPERATION_NAME,
    GET_PRESIGNED_URL_OPERATION_NAME
} from "../constants/constants";

export function uploadAttachment(tableDispatcher, request, onSuccess, onFailure) {
    tableDispatcher({
        type: "BEGIN", payload: {}
    });
    getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.fileName = request.fileName;
        input.body.documentType = request.documentType;
        input.body.useCase = request.useCase;
        input.body.operationName = GET_PRESIGNED_URL_OPERATION_NAME.UPLOAD;

        API.post("TWIN_API", PHASTOS_GET_S3_PRESIGNED_URL, input).then(async resp => {
            if (resp.status === 200) {
                // eslint-disable-next-line no-unused-vars
                const response = await axios.put(resp.body.presignedUrl, request.file);
                registerUploadedDocument(tableDispatcher, onSuccess, onFailure, request, resp?.body?.documentId);
            } else {
                onFailure();
                tableDispatcher({
                    type: "FAILURE", payload: {
                        errMsg: 'Failed to upload attachment.',
                        dispatcher: tableDispatcher,
                    }
                });
            }
        });
    });
}

export function uploadAdjustmentFile(tableDispatcher, request) {
    return getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.fileName = request.fileName;
        input.body.documentType = request.documentType;
        input.body.useCase = request.useCase;
        input.body.operationName = GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL_OPERATION_NAME.UPLOAD;

        return API.post("TWIN_API", GET_ADJUSTMENT_DOCUMENT_PRESIGNED_URL, input).then(async resp => {
            if (resp.status === 200) {
                // eslint-disable-next-line no-unused-vars
                const response = await axios.put(resp.body.presignedUrl, request.file);
                return await registerAdjustmentDocument(tableDispatcher, request, resp?.body?.documentId, resp?.body?.documentStoreKey);
            } else {
                tableDispatcher({
                    type: "API_REQUEST_FAILURE", payload: {
                        errMsg: 'Failed to upload adjustment file.',
                        dispatcher: tableDispatcher,
                    }
                });
            }
        });
    });
}